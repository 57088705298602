<template>
  <div class="viewCase" v-if="show">
    <div v-if="remark" class="shadow-box remark-box">
      <p class="remark-title"><van-icon class="remark-icon" name="warning" />驳回原因</p>
      <p class="remark-cont">{{remark}}</p>
    </div>
    <div class="formBox" v-for="(data, dindex) in list" :key="dindex">
      <template v-for="(item,index) in data.module">
        <van-form class="from" :key="index" v-if="showModule(item.input)">
          <div class="title">{{item.module_name}}</div>
          <template v-for="(itm, idx) in item.input">
            
            <div class="case_content" :key="idx">
              <div class="sub_title" v-if="itm.label == '1.药品名称' && ((itm.label == '1.药品名称' && itm.value != '')||(itm.label == '1.药物剂量' && itm.value != '') || (itm.label == '1.第几天给药' && itm.value != '')||(itm.label == '1.周期间隔' && itm.value != ''))">联合用药1</div>
              <div class="sub_title" v-if="itm.label == '2.药品名称' && ((itm.label == '2.药品名称' && itm.value != '')||(itm.label == '2.药物剂量' && itm.value != '') || (itm.label == '2.第几天给药' && itm.value != '')||(itm.label == '2.周期间隔' && itm.value != ''))">联合用药2</div>
              <div class="sub_title" v-if="itm.label == '3.药品名称' && ((itm.label == '3.药品名称' && itm.value != '')||(itm.label == '3.药物剂量' && itm.value != '') || (itm.label == '3.第几天给药' && itm.value != '')||(itm.label == '3.周期间隔' && itm.value != ''))">联合用药3</div>
              <template v-if="itm.type == 'file'">
                <van-field v-if="showValue(itm.value)" :label="itm.label" class="filed">
                  <template #input>
                    <div class="picBox">
                      <div class="list" v-for="(vitm,vidx) in itm.value" :key="vidx">
                        <van-image class="img" :src="vitm" :error-icon="itm.upload_filetype == 'image'?'photo-fail':'column'" :loading-icon="itm.upload_filetype == 'image'?'photo':'column'" @click="showImg(itm, vidx)">
                        </van-image>
                      </div>
                    </div>
                  </template>
                </van-field>
              </template>
              <template v-else-if="itm.type == 'configure'">
                <div v-if="ifShowCollapse && (itm.label == '恶心呕吐其他解救性治疗方案' || itm.label == '恶心呕吐其他预防方案' || itm.label == '5-HT-RA3其他药物' || itm.label == 'NK-1-RA其他药物')" class="father">
                </div>
                <div v-else class="father">
                  <van-field :label="itm.label" rows="1" autosize readonly>
                  </van-field>
                </div>
                <template v-for="(citm,cidx) in itm.child">
                  <div v-if="ifShowCollapse" :key="cidx">
                    <van-field rows="1" autosize :readonly="true">
                      <template #label>
                          <!-- <span>{{itm.label}}{{cidx+1}}----{{itm.configure_template_id}}</span> -->
                          <template v-if="itm.configure_template_id&&itm.config.secondary_title!=''&&itm.config.secondary_title">
                            <span>{{itm.config.secondary_title}}{{cidx+1}}</span>
                          </template>
                          <template v-else>
                            <span>{{itm.label}}{{cidx+1}}</span>
                          </template>
                      </template>
                    </van-field>
                  </div>
                  <div v-else class="child" :key="cidx">
                    <van-field rows="1" autosize :readonly="true">
                      <template #label>
                        <template v-if="itm.configure_template_id&&itm.config.secondary_title!=''&&itm.config.secondary_title">
                          <span>{{itm.config.secondary_title}}{{cidx+1}}</span>
                        </template>
                        <template v-else>
                            <span>{{itm.label}}{{cidx+1}}</span>
                        </template>
                          <!-- <span>{{itm.label}}{{cidx+1}}-----{{itm.configure_template_id}}</span> -->
                      </template>
                    </van-field>
                  </div>
                  <!-- <div v-if="ifShowCollapse && (itm.label == '恶心呕吐其他解救性治疗方案' || itm.label == '恶心呕吐其他预防方案' || itm.label == '5-HT-RA3其他药物' || itm.label == 'NK-1-RA其他药物')">
                    <template v-if="showValue(childItm.value)" class="child" v-for="childItm in citm">
                      <template v-if="childItm.type == 'file'">
                        <van-field :label="childItm.label" class="filed">
                          <template #input>
                            <div class="picBox">
                              <div class="list" v-for="(cvitm,cvidx) in childItm.value" :key="cvidx">
                                <van-image class="img" :src="cvitm" :error-icon="citm.upload_filetype == 'image'?'photo-fail':'column'" :loading-icon="citm.upload_filetype == 'image'?'photo':'column'" @click="showImg(citm, cidx)">
                                </van-image>
                              </div>
                            </div>
                          </template>
                        </van-field>
                      </template>
                      <template v-else>
                        <van-field :value="getValue(childItm.value)" rows="1" autosize type="textarea" :label="childItm.label" readonly />
                      </template>
                    </template>
                  </div> -->
                  <template v-if="showValue(childItm.value)" class="child" v-for="childItm in citm">
                    <template v-if="childItm.type == 'file'">
                      <van-field :label="childItm.label" class="filed">
                        <template #input>
                          <div class="picBox">
                            <div class="list" v-for="(cvitm,cvidx) in childItm.value" :key="cvidx">
                              <van-image class="img" :src="cvitm" :error-icon="citm.upload_filetype == 'image'?'photo-fail':'column'" :loading-icon="citm.upload_filetype == 'image'?'photo':'column'" @click="showImg(childItm, cvidx)">
                              </van-image>
                            </div>
                          </div>
                        </template>
                      </van-field>
                    </template>
                    <template v-else>
                      <van-field :value="getValue(childItm.value)" rows="1" autosize type="textarea" :label="childItm.label" readonly />
                    </template>
                  </template>
                </template>
              </template>
              <template v-else>
                <van-field v-if="showValue(itm.value)" :value="getValue(itm.value)" rows="1" autosize type="textarea" :label="itm.label" readonly />
              </template>
            </div>
          </template>
        </van-form>
      </template>
    </div>
    
    <div class="goBack" @click="onStep('back')">
        <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
    import {
        Toast,
        Dialog,
        ImagePreview,
        Image as VanImage
    } from "vant";
    import {
        checkCellphone,
        isIdCardNo
    } from '@/utils/utils.js'
    import Configure from './configure.vue'
    import Steps from '@/components/steps.vue'
    import Content from './content.vue'
    import {
        getForm,
        getFollowForm,
        subschemeAgre,
        schemeAgre,
        Finish,
        submit,
        getConfigureConfig
    } from '@/api/api'
    import {
        mapActions,
        mapState
    } from 'vuex';
    import {
        getAge,
        getSexById
    } from '@/utils/utils.js'
    export default {
        name: '',
        components: {
            Steps,
            Content,
            Configure
        },
        data() {
            return {
                show: false,
                disabled: false,
                steps: null,
                list: [],
                record_id: null,
                agreement: false,
                phase: 1,
                remark: '', // 审核失败原因
                short_name: '', //项目名
                ifShowCollapse: false, 
            }
        },
        computed: {
            ...mapState(['userId', 'doctor_id', 'basicInformation']),
            arr() {
                // console.log(this.list[this.steps].module, 'this.list[this.steps].module')
                return this.list[this.steps].module
            },
        },
        created() {
            this.phase = this.$route.query.phase ? this.$route.query.phase : 1
            this.record_id = this.$route.query.record_id ? this.$route.query.record_id : ''
            this.remark = this.$route.query.remark ? this.$route.query.remark : ''
            this.init()
        },
        mounted() {
          this.short_name = this.basicInformation.projects[0].short_name
          // console.log("项目名",this.short_name)
          if(this.short_name == "ztky") {  //@针对止吐科研项目，是否显示折叠和隐藏
            this.ifShowCollapse = true
          }
        },
        methods: {
            ...mapActions(['SET_REG_TYPE']),
            getRelationShow(itm) {
                let allList = []
                for (let i in this.list) {
                    let moduleList = this.list[i].module
                    for (let j in moduleList) {
                        let inputList = moduleList[j].input
                        for (let k in inputList) {
                            allList.push(inputList[k])
                        }
                    }
                }
                if (itm.asso && itm.asso.length > 0) {
                    let isshow = false
                    for (let i in itm.asso) {
                        for (let j in allList) {
                            if (itm.asso[i].name == allList[j].name) {
                                if (itm.asso[i].type == 'daterange') {
                                    if (itm.asso[i].value == allList[j].value.day) {
                                        isshow = true
                                    } else {
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                } else if (itm.asso[i].type == 'checkbox') {
                                    if (allList[j].value.indexOf(itm.asso[i].value) != -1) {
                                        isshow = true
                                    } else {
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                } else {
                                    if (itm.asso[i].value == allList[j].value) {
                                        isshow = true
                                    } else {
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                }

                            }
                        }
                    }
                    if (isshow) {
                        itm.isShow = true
                        return true
                    } else {
                        itm.isShow = false
                        return false
                    }
                } else {
                    itm.isShow = true
                    return true
                }
            },
            onIdsn(event) { //根据身份证获取性别和年龄
            let _this = this
                console.log('eeee', event)
                let input = this.list[this.steps].module[0].input
                let index = input.findIndex(ele => ele.name == 'gender')
                let sex = getSexById(event)
                if (index != -1) this.list[this.steps].module[0].input[index].value = sex
                let age = getAge(event)
                index = input.findIndex(ele => ele.name == 'age')
                if (index != -1) this.list[this.steps].module[0].input[index].value = age
            },
            async init() {
              var _this = this
                if (this.$route.query.phase && this.$route.query.phase > 1) {
                    this.record_id = ''
                }
                if(this.$route.query.is_followup&&this.$route.query.is_followup==1){
                  try {
                     this.list =await getFollowForm({
                        params:{
                            template_id:this.$route.query.template_id,
                            follow_id:this.$route.query.follow_id,
                            }
                        })
                        for(let i in this.list) {
                          let element = this.list[i]
                          for (let j in element.module) {
                            let ele = element.module[j]
                            for (let k in ele.input) {
                              let e = ele.input[k]
                              e.readonly = 1
                            }
                          }
                        }
                        this.show = true
                  } catch (error) {
                    Dialog.alert({
                        title: err.response.data.message,
                        confirmButtonColor: "#4B99FF",
                    }).then(() => {
                       this.$store.commit('setTabName','随访信息'); 
                        this.$router.replace({
                            path: '/progress',
                        })
                    });
                  }
                }else{
                    try {
                      this.list = await getForm({
                        params: {
                            doctor_id: this.userId,
                            scheme_id: this.$route.query.scheme_id,
                            subscheme_id: this.$route.query.subscheme_id,
                            phase: this.$route.query.phase ? this.$route.query.phase : 1,
                            record_id: this.record_id,
                            patient_id: (this.$route.query.phase && this.$route.query.phase > 1) ? this
                                .$route.query.patient_id : ''
                        }
                    })
                    for(let i in this.list) {
                      let element = this.list[i]
                      for (let j in element.module) {
                        let ele = element.module[j]
                        for (let k in ele.input) {
                          let e = ele.input[k]
                          e.readonly = 1

                          console.log(e.configure_template_id,'e.configure_template_id')
                          if(e.configure_template_id){
                            //console.log(e.configure_template_id,'e.configure_template_id 有')
                            await _this.getConfigure_config(e.configure_template_id).then(res =>{
                              e.config = res
                            })
                          }

                          
                            // (function(e){

                            //   if(e.configure_template_id!=null){
                            //     console.log(44444444)
                            //     _this.getConfigure_config(e.configure_template_id).then(res =>{
                            //       e.config = res
                            //     })
                            //   }

                            // }(e))
                          
                          
                        }
                      }
                    }
                    this.show = true
                } catch (err) {
                    Dialog.alert({
                        title: err.response.data.message,
                        confirmButtonColor: "#4B99FF",
                    }).then(() => {
                        this.$router.replace({
                            path: '/progress',
                        })
                    });
                }
                let res = null
                if (this.$route.query.subscheme_id && this.$route.query.subscheme_id > 0) {
                    res = await subschemeAgre({
                        params: {
                            id: this.$route.query.subscheme_id,
                            doctor_id: this.userId
                        }
                    })
                } else {
                 
                    res = await schemeAgre({
                        params: {
                            id: this.$route.query.scheme_id,
                            doctor_id: this.userId
                        }
                    })
                }
                if (res.need_sign == 1) {
                    this.agreement = true
                } else {
                    this.agreement = false
                }
              }        
                if (this.$route.query.steps) {
                    this.steps = this.list.length - 1
                } else {
                    this.steps = 0
                }
            },
            onStep(direction) {
                if (direction.record_id) this.record_id = direction.record_id
                if (direction == 'back') {
                    if (this.steps == 0) {
                      if(this.$route.query.is_followup&&this.$route.query.is_followup==1){
                        this.$store.commit('setTabName','随访信息');
                      }else{
                        this.$store.commit('setTabName','阶段病例');
                      }
                        this.$router.replace({
                            path: 'progress',
                        })

                    } else {
                        this.steps--
                    }
                }
                if (direction.type == 'up') {
                    if (this.steps == this.list.length - 1) {
                        if (this.agreement && (!this.$route.query.phase || (this.$route.query.phase && this.$route.query
                                .phase <= 1))) {
                            this.$router.replace({
                                path: '/case_agreement',
                                query: {
                                    record_id: this.record_id,
                                    scheme_id: this.$route.query.scheme_id,
                                    subscheme_id: this.$route.query.subscheme_id,
                                    phase: this.$route.query.phase ? this.$route.query.phase : 1
                                }
                            })
                        } else {
                            Dialog.alert({
                                message: '提交审核后不可修改，是否确认提交审核？',
                                title: '确定提交',
                                showCancelButton: true,
                                cancelButtonText: '我再想想',
                                confirmButtonColor: '#4B99FF',
                                confirmButtonText: '确定提交'
                            }).then(() => {
                                this.SET_REG_TYPE(null)
                                Finish({
                                    record_id: this.record_id,
                                }).then(res => {
                                    Toast.clear()
                                    Toast('提交成功')
                                    this.$router.replace({
                                        path: '/progress',
                                    })
                                }).catch(err => {
                                    Toast(err.response.data.message);
                                })
                            }).catch(() => {})

                        }

                    } else {
                        this.steps++
                    }
                }
            },
            showModule(arr) {
              let show = false
              for (let i in arr) {
                let element = arr[i]
                if (element.type == 'file') {
                  element.value.forEach(ele => {
                    if (ele) {
                      show = true
                    }
                    if (element.label=='联合用药证明') {
                      console.log('联合用药证明', Boolean(ele))
                    }
                  })
                  
                } else if (element.type == 'configure') {
                  if (element.hasOwnProperty('child')) {
                    for (let j in element.child) {
                      let ele = element.child[j]
                      for (let k in ele) {
                        if (ele[k].type == 'file') {
                          if(ele[k].value != "") {
                            ele[k].value.forEach(el => {
                              if (el) {
                                show = true
                              }
                            })
                          }
                        } else {
                          if (ele[k].value) {
                            show = true
                          }
                        }
                      }
                    }
                  }
                } else {
                  if (this.showValue(element.value)) show = true
                }
              }
              return show
            },
            showValue(val) {
              console.log(val,'val');
              let show = false
              if (Array.isArray(val)) {
                val.forEach(ele => {
                  if (ele) {
                    show = true
                  }
                })
              } else {
                if (val) show = true
              }
              console.log('show',show);
              return show
            },
            getValue(val) {
              if (Array.isArray(val)) {
                let str = ''
                val.forEach((ele, index) => {
                  if (str) str = str + '，'
                  if (ele) {
                    str = str + ele
                  }
                })
                return str
              } else {
                return val
              }
            },
            showImg(item, index) {
              console.log("图片类型",item.upload_filetype)
              if (item.upload_filetype != 'image') {
                Toast('非图片材料暂不支持查看')
                return
              }
              let idx = index
              let images = item.value
              this.$nextTick(() => {
                ImagePreview({
                  images,
                  startPosition: idx,
                  closeable: true
                });
              })
            },
            getConfigure_config(id) {
                 let template_structure = getConfigureConfig({
                    params: {
                        configure_template_id: id
                    }
                })
                return template_structure
            }
        }
    }
</script>

<style lang="scss" scoped>
  .title {
      padding-left: 11px;
      box-sizing: border-box;
      font-size: 15px;
      color: #232323;
      margin-bottom: 13px;
      text-align: left;
  }

  .formBox {
      padding: 0 11px;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      box-sizing: border-box;

      .from {
          // margin-bottom: 30px;
      }
  }

  .goBack {
      position: fixed;
      right: 11px;
      bottom: 150px;
      height: 45px;
      width: 45px;

      img {
          height: 100%;
          width: 100%;
      }

  }

  .viewCase {
    width: 100%;

    .top {
        width: 100%;
        height: 125px;
        background: url('/static/stepsBackground.png');
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 14px;

        .steps {
            width: 100%;
            margin-bottom: 17px;
        }

    }
    .case_content {
      position: relative;
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
      }
      .sub_title{
          font-size: 15px;
          display: flex;
          margin-left: 15px;
          // color: #000;
          color: #646566;
          padding: 15px 0 6px;
      }
    }

    .picBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .list {
        height: 50px;
        width: 50px;
        margin-right: 10px;
        margin-bottom: 5px;
        position: relative;

        .img {
          height: 100%;
          width: 100%;
        }

        .clear {
          position: absolute;
          right: -2px;
          top: -2px;
          height: 13px;
          width: 13px;

        }
      }
    }
  }
</style>